import React from "react"
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import * as styles from './news-post.module.scss'
import { Link } from "react-scroll"
const crypto = require("crypto")

const TableOfContents = ({ data }) => {
  const headingTypes = [BLOCKS.HEADING_1,BLOCKS.HEADING_2, BLOCKS.HEADING_3]

  const headings = JSON.parse(data.raw).content.filter(item => headingTypes.includes(item.nodeType))
  const document = {
    nodeType: "document",
    content: headings,
  }

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return (
          <li className={styles.item}>
            <Link
              to={anchor}
              activeClass="active"
              smooth={true}
              duration={500}
              offset={-70}
            >
              {children}
            </Link>
          </li>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return (
          <li className={styles.item}>
            <Link
              to={anchor}
              activeClass="active"
              smooth={true}
              duration={500}
              offset={-70}
            >
              {children}
            </Link>
          </li>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return (
          <li className={styles.item}>
            <Link
              to={anchor}
              activeClass="active"
              smooth={true}
              duration={500}
              offset={-70}
            >
              {children}
            </Link>
          </li>
        )
      },
    }
  }

  return (
    <nav className={styles.tocBox}>
      <p className={styles.pageTitle}>Topic</p>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Link
            to="pageTitle"
            activeClass="active"
            smooth={true}
            duration={500}
            offset={-70}
          >
            Top
          </Link>
        </li>
        {documentToReactComponents(document, options)}
      </ul>
    </nav>
  )
}

export default TableOfContents