import React from 'react'
import { Link, graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage} from 'gatsby-plugin-image'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TableOfContents from "../components/tableofcontents"
import Share from "../components/share"
import * as styles from '../components/news-post.module.scss'
import RelatedNews from '../components/related-news'
import { useLocation } from "@reach/router"
const NewsPostTemplate = ({data}) => {
  const post = data.contentfulNewsPost
  const site = data.site
  const crypto = require("crypto")
  const tags = post.metadata.tags;
  const location = useLocation();
  let authorIcon = false
  let authorName = false
  let authorRole = false
  let url = false
  const options = {
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <Link to={uri} target="_blank">
            {children}
          </Link>
        )
      },
      [BLOCKS.HEADING_1]: (node,children) => {
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return <h2 className={styles.heading1} id={anchor}>{children}</h2>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return <h3 className={styles.heading2} id={anchor}>{children}</h3>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return <h4 className={styles.heading3} id={anchor}>{children}</h4>
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return <h5 className={styles.heading2} id={anchor}>{children}</h5>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target
        return (
          <div className={styles.img}>
            <GatsbyImage
            image={getImage(gatsbyImageData)}
            alt={description}
            />
          </div>
        )
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li>{children}</li>
      },
    },
  }
  if(post.authorIcon){
    authorIcon = true
  }
  if(post.authorName){
    authorName = true
  }
  if(post.authorRole){
    authorRole = true
  }
  if(post.url){
    url = true
  }
  return (
    <Layout className={styles.mainWrap} headerCheck={false} page="news-post">
      <Seo
        title={post.title + " | ニュース | becoz (ビコーズ)"}
        description={post.description}
        image={post.keyVisual ? post.keyVisual.url : null}
      />
      <article className={styles.articleWrap}>
        <div className={styles.flexAlignCenter}>
          <p className={styles.pageTitle}>News</p>
          <div className={styles.infoBox}>
            <div className={styles.tagsBox}>
              <ul>
                {tags.map(tag => {
                  return (
                    <li key={tag}>
                      <Link className={tag.contentful_id} to={"/news/tag/" + tag.contentful_id}>#{tag.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={styles.dateBox}>
              <p>{post.publishDate}</p>
            </div>
          </div>
        </div>
        <div className={styles.title}>
          <h1 id="pageTitle">
            {post.title}
          </h1>
        </div>
        <div className={styles.profileBox}>
            {authorIcon
              &&
              <div className={styles.profileBoxImg}>
                <GatsbyImage image={getImage(post.authorIcon.gatsbyImageData)} alt={post.authorName}/>
              </div>
            }

          <div className={styles.profileBoxText}>
            {authorName
              &&
              <div className={styles.name}>{post.authorName}</div>
            }
            {authorRole
              &&
              <div className={styles.position}>{post.authorRole}</div>
            }
          </div>
        </div>
        <div className={styles.contentWrap}>
          <div className="tableOfContents">
            <TableOfContents data={post.articleBody} />
          </div>
          <div id="articleBody">
            <div className={styles.articleBody}>
              {post.articleBody?.raw && renderRichText(post.articleBody,options)}
            </div>
            <div className={styles.articleInfo}>
              <div className={styles.articleInfoUrl}>
                {url && <p className={styles.urlTitle}>URL</p>}
                <Link className={styles.url} target="_blank" to={post.url}>{post.url}</Link>
              </div>
              <Share
                title={post.title}
                url={`${site.siteMetadata.siteUrl}${location.pathname}`}
              />
            </div>

          </div>
        </div>
      </article>

      <RelatedNews views={3} id={post.id} tag={post.metadata.tags[0]} />
    </Layout>
  )
}
export default NewsPostTemplate

export const pageQuery = graphql`
  query NewsPostBySlug(
    $slug: String!
  ) {
    contentfulNewsPost(slug: { eq: $slug }, node_locale: { eq: "ja" }) {
      node_locale
      id
      slug
      title
      publishDate(formatString: "YYYY.MM.DD")
      url
      keyVisual {
        url
        gatsbyImageData
      }
      authorIcon{
        url
        gatsbyImageData
      }
      authorName
      authorRole
      articleBody {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData
            __typename
          }
        }
      }
      description
      metadata {
        tags {
          contentful_id
          name
        }
      }
    }
    site {
      siteMetadata{
        siteUrl
      }
    }
  }
`
