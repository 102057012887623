import React from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
} from "react-share"
import CopyToClipboard from "react-copy-to-clipboard"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import * as styles from "./share.module.scss"
import TwitterIcon from "../svgs/common/icons/sns_icon_twitter.svg"
import FacebookIcon from "../svgs/common/icons/sns_icon_facebook.svg"
import LineIcon from "../svgs/common/icons/sns_icon_line.svg"
import ShareIcon from "../svgs/common/icons/icon_share.svg"
const Share = props => {
  const articleTitle = props.title
  const articleUrl = props.url
  const [open, setOpen] = React.useState(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleTooltipOpen = () => {
    setOpen(true)
  }
  return (
    <div className="share">
      <p className={styles.shareTitle}>Share</p>
      <div className={styles.socialLinks}>
        <div className={styles.snsBtn}>
          <TwitterShareButton url={articleUrl} title={articleTitle}>
            <TwitterIcon />
          </TwitterShareButton>
        </div>
        <div className={styles.snsBtn}>
          <FacebookShareButton url={articleUrl}>
            <FacebookIcon />
          </FacebookShareButton>
        </div>
        <div className={styles.snsBtn}>
          <LineShareButton url={articleUrl} title={articleTitle}>
            <LineIcon />
          </LineShareButton>
        </div>
        <div className={styles.snsBtn}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              placement="top"
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              title="リンクをコピーしました"
              componentsProps={{
                tooltip: { sx: { 
                  backgroundColor: "#81D8D0",
                  color:"#fff",
                  borderRadius:"14px",
                  fontFamily: "'Zen Kaku Gothic New', sans-serif",
                  fontSize: "14px",
                  padding:"4px 10px"
                } },
                arrow: { sx: { 
                  color:"#81D8D0",
                  opacity:"1 !important",
                } },
              }}
            >
              <Button
                onClick={() => {
                  handleTooltipOpen()
                  setTimeout(() => handleTooltipClose(), 800)
                }}
              >
                <CopyToClipboard text={articleUrl}>
                  <ShareIcon />
                </CopyToClipboard>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Share
