import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import ArticlePreview from "./article-preview"
import * as styles from '../components/related-news.module.scss'
const RelatedNews = ({ views, id, tag }) => {
  const relatedPosts = useStaticQuery(graphql`
    query {
      allContentfulNewsPost(filter: {
        node_locale: {
          eq: "ja"
        }
      }) {
        nodes {
          title
          slug
          id
          publishDate(formatString: "YYYY.MM.DD")
          metadata {
            tags {
              contentful_id
              name
            }
          }
          keyVisual {
            url
            gatsbyImageData
          }
        }
      }
    }
  `)
  const posts = relatedPosts.allContentfulNewsPost.nodes.filter(
    node => node.id !== id && node.metadata.tags[0].contentful_id === tag.contentful_id
  )

  if (!posts.length) {
    return null;
  }

  posts.length = views;

  return (
    <div className={styles.relatedNews}>
      <div>
        <p className={styles.pageTitle}>Related News</p>
      </div>
      <div className={styles.articleWrap}>
        <ArticlePreview posts={posts} />
      </div>
      <div className={styles.btnBox}>
        <Link to="/news/" className={styles.btn}>ニュース一覧</Link>
      </div>
    </div>
  )
}

export default RelatedNews
