// extracted by mini-css-extract-plugin
export var articleBody = "news-post-module--article-body--065d3";
export var articleInfo = "news-post-module--article-info--245ca";
export var articleInfoUrl = "news-post-module--article-info-url--6d43b";
export var articleWrap = "news-post-module--article-wrap--5d152";
export var contentWrap = "news-post-module--content-wrap--cb5ac";
export var dateBox = "news-post-module--date-box--5af39";
export var flexAlignCenter = "news-post-module--flex-align-center--ecf86";
export var heading1 = "news-post-module--heading1--27d9b";
export var heading2 = "news-post-module--heading2--afa18";
export var heading3 = "news-post-module--heading3--40a41";
export var img = "news-post-module--img--bdf57";
export var infoBox = "news-post-module--info-box--070be";
export var item = "news-post-module--item--7ae54";
export var list = "news-post-module--list--87a93";
export var mainWrap = "news-post-module--main-wrap--6c57d";
export var name = "news-post-module--name--eb289";
export var pageTitle = "news-post-module--page-title--c0681";
export var position = "news-post-module--position--bb232";
export var profileBox = "news-post-module--profile-box--b878c";
export var profileBoxImg = "news-post-module--profile-box-img--480c7";
export var profileBoxText = "news-post-module--profile-box-text--81b36";
export var tagsBox = "news-post-module--tags-box--b84aa";
export var title = "news-post-module--title--ecebc";
export var tocBox = "news-post-module--toc-box--a7b61";
export var url = "news-post-module--url--64f87";
export var urlTitle = "news-post-module--url-title--a561c";